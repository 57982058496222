import React, { useState, useEffect } from 'react';
import './TokenList.styles.css'
import { useWeb3React } from "@web3-react/core"
import abi from "../../scripts/abi.json"
import token from '../../assets/token.m4v'
import { CONTRACT_ADDRESS } from '../../scripts/contract';

const TokenList = ({ setToken }) => {

    const [numTokens, setNumTokens] = useState([])
    const [numWhite, setNumWhite] = useState(0)
    const [numGold, setNumGold] = useState(0)
    const [contract, setContract] = useState(null)
    let whiteTokens = []
    let goldTokens = []

    const { active, account, library, error } = useWeb3React()
    const address = CONTRACT_ADDRESS

    useEffect(() => {
        if (library && contract == null) {
            let newContract = new library.eth.Contract(abi, address)
            setContract(newContract)
        }
    }, [library, contract])

    useEffect(() => {
        const getTokens = async () => {
            if (contract) {
                console.log(contract)
                let balanceWhite = await contract.methods.balanceOf(account, 0).call()
                let balanceGold = await contract.methods.balanceOf(account, 1).call()
                setNumWhite(balanceWhite)
                setNumGold(balanceGold)
    
                console.log('Whites:', balanceWhite)
                console.log('Golds:', balanceGold)
            }
        }
        getTokens()
    }, [account, contract])

    return (
        <div id='tokenList'>
            <div className='tokens'>
                <video
                    className='redeem-token'
                    muted
                    loop
                    autoPlay
                    id='white-token'
                >
                    <source src={token} type='video/mp4' />
                </video>
                <span className='token-number'>x {numWhite}</span>
                <span 
                className='redeem-btn' 
                onClick={() => {
                    if (numWhite >= 1) setToken(0)
                }}
                >REDEEM 1</span>
            </div>
            <div className='tokens'>
                <video
                    className='redeem-token'
                    muted
                    loop
                    autoPlay
                    id='gold-token'
                >
                    <source src={token} type='video/mp4' />
                </video>
                <span className='token-number'>x {numGold}</span>
                <span 
                className='redeem-btn'
                onClick={() => {
                    if (numGold >= 1) setToken(1)
                }} >REDEEM 1</span>
            </div>
        </div>
    );
};

export default TokenList;