import React, { useState } from 'react';
import './Login.styles.css'

const Login = ({ tryLogin, error }) => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    return (
        <div className='login-form'>
            <input className='login-input' type='text' onChange={(e) => setUsername(e.target.value)} value={username} placeholder='Username'/>
            <input className='login-input' type='password' onChange={(e) => setPassword(e.target.value)} value={password} placeholder='Password' />
            {error && <p>{error}</p>}
            <span className='login-btn' onClick={() => tryLogin(username, password) }>Login</span>
        </div>
    )
}

export default Login