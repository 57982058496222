import React, { useState, useEffect, useRef } from "react"
import Modal from "react-modal"
import { Link, useNavigate } from "react-router-dom"
import white_animation from "../../assets/white-reveal.m4v"
import gold_animation from "../../assets/gold-reveal.m4v"
import token from "../../assets/token.m4v"
import abi from "../../scripts/abi.json"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import { injected } from "../../scripts/connector"
import "./Mint.styles.css"

import Header from "../../components/Header/Header.component"
import Footer from "../../components/Footer/Footer.componet"
import { CONTRACT_ADDRESS } from "../../scripts/contract"

const Mint = () => {
  const { active, account, library, activate, error } = useWeb3React()
  const isUnsupportedChainId = error instanceof UnsupportedChainIdError
  const [isLoading, setIsLoading] = useState(false)
  const [buttonText, setButtonText] = useState("CONNECT")
  const [contract, setContract] = useState(null)
  const [isPresale, setIsPreSale] = useState(false)
  const [isSale, setIsSale] = useState(false)
  const [confirmedMint, setConfirmedMint] = useState(false)
  const [price, setPrice] = useState(null)
  const address = CONTRACT_ADDRESS
  const navigate = useNavigate()

  Modal.setAppElement("#root")

  useEffect(() => {
    if (library && contract == null) {
      let newContract = new library.eth.Contract(abi, address)
      setContract(newContract)
    }
  }, [library, contract])

  useEffect(() => {
    if (account) {
      setButtonText(`MINT BAYC1`)
    } else {
      setButtonText("CONNECT")
    }
  }, [account])

  useEffect(() => {
    const getSaleStatus = async () => {
      if (active && contract) {
        let sale = await contract.methods.saleActive().call()
        let presale = await contract.methods.presaleActive().call()

        if (sale == 1) {
          setIsSale(true)
          setIsPreSale(false)
          let mintPrice = await contract.methods.mintPrice().call()
          mintPrice = mintPrice / 10 ** 18
          setPrice(mintPrice)
          setButtonText(`MINT BAYC1 ${mintPrice}E`)
        }
        if (presale == 1) {
          setIsPreSale(true)
          setIsSale(false)
          setButtonText("MINT PRESALE")
        }
        if (sale == 0) {
          setButtonText("Sale currently paused")
        }
      }
    }

    getSaleStatus()
  }, [contract])

  const playVideo = (name) => {
    const vid = document.getElementById("mint-animation")

    if (vid.currentSrc !== name) {
      vid.src = name
      vid.play()
    }
    vid.play()
    console.log("playing video")
  }

  const handleClick = async () => {
    let isOnWhitelist
    if (active && !isLoading && isPresale) {
      try {
        let res = await contract.methods.whitelist(account).call()
        if (res == 1) isOnWhitelist = true
      } catch (error) {}
    }

    if (active && !isLoading && ((isPresale && isOnWhitelist) || isSale)) {
      // mint
      let price
      if (isPresale) {
        price = await contract.methods.presalePrice().call()
      } else {
        price = await contract.methods.mintPrice().call()
      }

      setIsLoading(true)
      setButtonText("LOADING...")
      let tx
      let estimatedGas
      try {
        if (isPresale) {
          estimatedGas = await contract.methods.presalePurchase().estimateGas({
            from: account,
            value: price,
          })
        } else {
          estimatedGas = await contract.methods.purchase().estimateGas({
            from: account,
            value: price,
          })
        }
      } catch (error) {
        console.log(error)
      }
      if (estimatedGas !== null) {
        try {
          if (isPresale) {
            tx = await contract.methods.presalePurchase().send({
              from: account,
              value: price,
              gas: estimatedGas + 20000,
            })
          } else {
            tx = await contract.methods.purchase().send({
              from: account,
              value: price,
              gas: estimatedGas + 20000,
            })
          }
        } catch (error) {
          console.log("error:", error)
          setIsLoading(false)
          setButtonText("MINT BAYC1")
        }
      }

      console.log("tx:", tx)

      if (tx) {
        setIsLoading(false)
        setConfirmedMint(true)
        setButtonText("MINT BAYC1")
      }
    } else {
      // connect
      try {
        activate(injected)
      } catch (error) {
        console.log(error)
        setButtonText("CONNECT")
      }
      if (isPresale) {
        setButtonText(`MINT PRESALE`)
      } else if (isSale) {
        setButtonText(`MINT BAYC1 ${price}E`)
        console.log("set button text")
      } else {
        setButtonText("Sale currently paused")
      }
    }
  }

  const closeModal = () => {
    setConfirmedMint(false)
  }

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  return (
    <div className="mintPage">
      <Header />
      <main>
        <Modal
          isOpen={confirmedMint}
          onRequestClose={closeModal}
          style={modalStyles}
          className="confirmation-modal"
          overlayClassName="modal-overlay"
        >
          <h3>CONGRATS!</h3>
          <p>You've minted a BAYC1 Token!</p>
          <div className="modal-buttons">
            <div className="modal-btn" onClick={() => navigate("/redeem")}>
              <span>REDEEM</span>
            </div>
            <div className="modal-btn" onClick={() => setConfirmedMint(false)}>
              <span>CLOSE</span>
            </div>
          </div>
        </Modal>
        <div className="animation-container">
          <video id="mint-animation" muted loop autoPlay>
            <source id="mint-animation-src" src={token} type="video/mp4" />
          </video>
        </div>
        <div id="mint-container">
          <div id="mint-button" onClick={handleClick}>
            <span>{buttonText}</span>
          </div>

          {isUnsupportedChainId ? (
            <span className="bad-chain-id">
              Please change network to Ethereum mainnet
            </span>
          ) : null}
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Mint
