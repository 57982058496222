import React, { useState } from "react"
import axios from "axios"
import { appendErrors, useForm } from "react-hook-form"
import "react-widgets/styles.css"
import "./RedeemForm.styles.css"

const RedeemForm = ({ account, handleFormSubmit, tokenId, submitEntered }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const [email, setEmail] = useState("")
	const [nametag, setNametag] = useState("")
	const [twitter, setTwitter] = useState("")
	const [photo, setPhoto] = useState(null)
	const [jacketType, setJacketType] = useState("Button")
	const [size, setSize] = useState("Small")
	const [shippingAddress, setShippingAddress] = useState("")

	const handleClick = () => {}

	const onSubmit = (data) => {
		let formData = new FormData()
		for (const [key, value] of Object.entries(data)) {
			if (key == "image") {
				formData.append(key, value[0])
				continue
			}
			formData.append(key, value)
		}
		formData.append("token", tokenId)

		handleFormSubmit(formData)
	}

	return (
		<div className="redeemForm">
			{account ? (
				<div id="redeem-form">
					<form onSubmit={handleSubmit(onSubmit)}>
						<p>Email</p>
						{errors.email && (
							<p className="error">Please enter valid email address</p>
						)}
						<input type="email" {...register("email", { required: true })} />

						<p>Nametag (Optional)</p>
						{errors.nametag && (
							<p className="error">Please enter a nametag (Optional)</p>
						)}
						<input {...register("nametag")} />
						<i>
							We will print{" "}
							<b>
								<u>exactly</u>
							</b>{" "}
							what you input
						</i>

						<p>Twitter</p>
						<input {...register("twitter")} />

						<p>Jacket Type</p>
						{errors.jacketType && <p className="error">Select a jacket type</p>}
						<select {...register("jacketType", { required: true })}>
							<option value="" disabled selected>
								Select jacket type
							</option>
							<option value="button">Button</option>
							<option value="zip">Zip</option>
						</select>

						<p>Size</p>
						{errors.size && <p className="error">Select a size</p>}
						<select {...register("size", { required: true })}>
							<option value="" disabled selected>
								Select your size
							</option>
							<option value="small">S</option>
							<option value="medium">M</option>
							<option value="large">L</option>
							<option value="xl">XL</option>
							<option value="xxl">XXL</option>
							<option value="xxxl">XXXL</option>
						</select>

						<p>Ape Number</p>
						{errors.number && (
							<p className="error">Must include your Ape number</p>
						)}
						<input {...register("number", { required: true })} />

						<p>Ape Photo</p>
						{errors.photo && (
							<p className="error">Must upload image of your ape</p>
						)}
						<input type="file" {...register("image", { required: true })} />
						<i>Photo of ape to be embroidered to jacket</i>

						<p style={{ marginBottom: "10px", fontSize: "1.4em" }}>
							Shipping Details
						</p>

						<p>Street Address</p>
						<input {...register("address", { required: true })} />
						<p>City</p>
						<input {...register("city", { required: true })} />

						<p>State / Province</p>
						{errors.state && (
							<p className="error">Must enter state / province</p>
						)}
						<input {...register("state", { required: true })} />

						<p>Country</p>
						{errors.country && <p className="error">Must enter country</p>}
						<input {...register("country", { required: true })} />

						<p>ZIP / Postal Code</p>
						{errors.zip && <p className="error">Must enter zip/postal code</p>}
						<input {...register("zip", { required: true })} />
						<button id="submit-btn" type="submit">
							{submitEntered ? "LOADING..." : "SUBMIT"}
						</button>
					</form>
				</div>
			) : (
				<div id="connect-btn" onClick={handleClick}>
					<span>CONNECT</span>
				</div>
			)}
		</div>
	)
}

export default RedeemForm
