import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import './Burger.styles.css'

const Burger = () => {

    return (
        <Menu className='menu' isOpen={false} right>
            <Link to='/'>Home</Link>
            <Link to='/'>Mint</Link>
            <Link to='/redeem'>Redeem</Link>
        </Menu>
    )
}

export default Burger