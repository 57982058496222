import React from 'react';
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai'
import { FaDiscord } from 'react-icons/fa'
import opensea_icon from '../../assets/opensea-icon.png'
import './Footer.styles.css'

const Footer = () => {
    return (
        <div id='footer'>
            <div id='footer-icons'>
                <a href='https://twitter.com/workwaresio'><AiFillTwitterCircle color='white' size='3em' /></a>
                <a href='https://www.instagram.com/workwares/'><AiFillInstagram color='white' size='3em' /></a>
                <a href='https://discord.gg/E6tGEstn'><FaDiscord color='white' size='3em' /></a>
                <a href='https://opensea.io/collection/workwares'><img alt='' src={opensea_icon} id='opensea-icon' /></a>
            </div>
        </div>
    )
}

export default Footer