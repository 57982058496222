import React, { useState, useEffect } from 'react'
import './Redeem.styles.css'
import axios from 'axios'
import abi from "../../scripts/abi.json"
import { CONTRACT_ADDRESS } from '../../scripts/contract'
import white_reveal from '../../assets/white-reveal.m4v'
import gold_reveal from '../../assets/gold-reveal.m4v'
import white_polaroid from '../../assets/white-polaroid.m4v'
import gold_polaroid from '../../assets/gold-polaroid.m4v'
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import Modal from 'react-modal'

import Header from '../../components/Header/Header.component'
import Footer from '../../components/Footer/Footer.componet'
import RedeemForm from '../../components/RedeemForm/RedeemForm.component'
import TokenList from '../../components/TokenList/TokenList.component'
import { injected } from "../../scripts/connector"

const Redeem = () => {
    const [contract, setContract] = useState(null)
    const [tokenToRedeem, setTokenToRedeem] = useState(null)
    const [isConnected, setIsConnected] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [animationsFinished, setAnimationsFinished] = useState(false)
    const [submitEntered, setSubmitEntered] = useState(false)

    const address = CONTRACT_ADDRESS
    const { active, account, library, activate, deactivate, error } = useWeb3React()

    Modal.setAppElement('#root')

    useEffect(() => {
        if (library && contract == null) {
            console.log('found library...')
            let newContract = new library.eth.Contract(abi, address)
            console.log('making new contract...')
            setContract(newContract)
        }
    }, [library, contract])

    useEffect(() => {
        if (account) {
            setIsConnected(true)
        } else {
            setIsConnected(false)
        }
    }, [account]);

    const handleClick = () => {        
        try {
            activate(injected)
        } catch (error) {
            setErrorMessage(error)
        }
    }

    const modalStyles = {
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    }

    const closeModal = () => {
        setTokenToRedeem(null)
        setFormSubmitted(false)
        setAnimationsFinished(false)
    }

    const handleFormSubmit = (formData) => {
        if (!submitEntered) {
            setSubmitEntered(true)
            axios.post('/upload', formData, {
                    headers: {
                        "Content-Type": `multipart/form-data`
                    }
            })
            .then(async res => {
                if (res.data.message === 'success') {
                    // call redeem function
                    const estimatedGas = await contract.methods.redeemToken(tokenToRedeem).estimateGas({
                        from: account
                    })
                    let tx = null
                    try {
                        tx = await contract.methods.redeemToken(tokenToRedeem).send({
                            from: account,
                            gas: estimatedGas + 10000
                        })                        
                    } catch (error) {
                        console.log('Metamask cancelled')
                        setSubmitEntered(false)
                    }
                    if (tx) {
                        console.log('redeem confirmed')
                        axios.post('/submit', formData, {
                            headers: {
                                "Content-Type": `multipart/form-data`
                            }
                        })
                        .then(() => {
                            setFormSubmitted(true)
                            setSubmitEntered(false)
                        })
                        .catch(err => {

                        })                        
                    }
                }
    
            })
            .catch((e) => {
                console.error(e)
            })
        }
    }

    const handleVideoEnd = () => {
        let vid = document.getElementById('reveal-anim')
        let source = document.getElementById('reveal-anim-source')
        if (source.getAttribute('src') == white_reveal || source.getAttribute('src') == gold_reveal) {
            
            setAnimationsFinished(true)
        }
        if (source.getAttribute('src') == white_polaroid || source.getAttribute('src') == gold_polaroid) {
            tokenToRedeem == 0 ?
            source.setAttribute('src', white_reveal)
            : source.setAttribute('src', gold_reveal)

            vid.load()
            vid.play()
        }
        
    }

    return (
        <div className='redeemPage'>
            <Header />
            <main id='redeem-main'>
            <Modal 
                isOpen={animationsFinished}
                onRequestClose={closeModal}
                style={modalStyles}
                className='redeem-confirmation-modal'
                overlayClassName='modal-overlay'
            >
                <div id='modal-dialog'>
                    <h1>Thank you for redeeming bayc1!</h1>
                    <p>Head over to the workwares discord to hang out. If you have any questions about your order, you can submit a ticket in the #support channel</p>
                    <div id='redeem-modal-btns'>
                        <span className='redeem-modal-btn'><a href='https://discord.gg/E6tGEstn'>Discord</a></span>
                        <span className='redeem-modal-btn'><a href={`https://opensea.io/assets/0x2f69dedd3f47968c504b8e853131a54662a34b98/${tokenToRedeem+2}`}>View on OpenSea</a></span>
                        <span className='redeem-modal-btn' onClick={closeModal}>Go Back</span>
                    </div>
                </div>
            </Modal>
                {
                    !account ?
                    <div id="mint-button" onClick={handleClick}>
                        <span>CLICK HERE TO CONNECT</span>
                    </div>
                    : null
                }
                {
                    tokenToRedeem == null && account ? <TokenList account={account} setToken={setTokenToRedeem}/>
                    : null
                }
                {
                    (tokenToRedeem == 0 || tokenToRedeem == 1) && !formSubmitted?
                    <RedeemForm account={account} handleFormSubmit={handleFormSubmit} tokenId={tokenToRedeem} submitEntered={submitEntered} />
                    : null
                }
                {
                    (tokenToRedeem == 0 || tokenToRedeem == 1) && formSubmitted === true ?
                    <div id='video-container'>
                        <div className='redeem-token-anim-container'>
                            <video
                                muted
                                autoPlay
                                id='reveal-anim'
                                onEnded={handleVideoEnd}
                            >
                                <source
                                    id='reveal-anim-source'
                                    src={tokenToRedeem == 0 ? white_polaroid : gold_polaroid} 
                                    type='video/mp4' 
                                />
                            </video>
                        </div>
                    </div>
                    : null
                }
            </main>
            <Footer />
        </div>
    )
}

export default Redeem