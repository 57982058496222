import React from "react"
import "./RedeemItem.styles.css"

const RedeemItem = ({
	item: {
		email,
		nametag,
		twitter,
		jacketType,
		size,
		number,
		address,
		city,
		state,
		country,
		zip,
		token,
		jacketId,
		image,
	},
}) => {
	return (
		<div className="redeem-item">
			<img alt="" src={`https://bayc1.mintworkwares.io/images/${image}`} />
			<div className="redeem-item-info">
				<p>
					<b>Email: </b>
					{email}
				</p>
				<p>
					<b>Nametag: </b>
					{nametag}
				</p>
				<p>
					<b>Twitter: </b>
					{twitter}
				</p>
				<p>
					<b>Jacket Type: </b>
					{jacketType}
				</p>
				<p>
					<b>Size: </b>
					{size}
				</p>
				<p>
					<b>Ape Number: </b>
					{number}
				</p>
				<p>
					<b>Address: </b>
					{address}
				</p>
				<p>
					<b>City: </b>
					{city}
				</p>
				<p>
					<b>State: </b>
					{state}
				</p>
				<p>
					<b>Country: </b>
					{country}
				</p>
				<p>
					<b>Zip/PostalCode: </b>
					{zip}
				</p>
				<p>
					<b>Token: </b>
					{token == 0 ? "White" : "Gold"}
				</p>
				<p>
					<b>Jacket ID: </b>
					{jacketId}
				</p>
			</div>
		</div>
	)
}

export default RedeemItem
