import React from 'react'
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core'

import Mint from './pages/Mint/Mint.component'
import Redeem from './pages/Redeem/Redeem.component'
import Admin from './pages/Admin/Admin.component'

import './App.css';


const getLibrary = (provider) => {
  return new Web3(provider)
}

function App() {

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <Routes>
          <Route path='/' element={<Mint />} />
          <Route path='/redeem' element={<Redeem />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </Router>
    </Web3ReactProvider>
  );
}

export default App;
