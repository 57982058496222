import React, { useState, useEffect } from 'react';
import './Admin.styles.css'

import axios from 'axios'

import RedeemList from '../../components/RedeemList/RedeemList.component'
import Login from '../../components/Login/Login.component'

const Admin = () => {

    const [items, setItems] = useState([])
    const [loggedIn, setLoggedIn] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const getItems = async () => {
            let res = await axios.get('/items')
            setItems(res.data)
        }

        getItems()
    }, [items])

    const handleLogin = async (username, password) => {
        let res
        try {
            res = await axios.post('/login', {
                username,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        } catch (error) {
            setError(error.message)
        }
        if (res.data.message === 'success') {
            setLoggedIn(true)
        } else {
            setError(res.data.error)
        }
        
    }

    return (
        <div className='admin-page'>
        {
            loggedIn ?
            <RedeemList items={items} />
            : <Login tryLogin={handleLogin} error={error}/>
        }
        </div>
    )
}

export default Admin