import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Burger from '../Burger/Burger.component'
import './Header.styles.css'

const Header = () => {

    const { active, account } = useWeb3React()
    const [connectedText, setConnectedText] = useState('Not connected')

    useEffect(() => {
        if (account) {
            setConnectedText(account.substr(0, 6).concat('...'))
        } else {
            setConnectedText('Not connected')
        }
    }, [account]);

    let admin_acct = '0xe026817Ba1618ebe44b0a1C44dB76261F6D1Cb2D'

    return (
        <div className='header'>
            <span
                className='connected-text'
            >
                {connectedText}
            </span>
            <Burger id='burger' />
            <nav id='nav'>
            <ul>
                <Link to='/'>HOME</Link>
                <Link to='/'>MINT</Link>
                <Link to='/redeem'>REDEEM</Link>
                {
                    account == admin_acct && <Link to='/admin'>ADMIN</Link>
                }
                </ul>
            </nav>
        </div>
    )
}

export default Header