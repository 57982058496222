import React from 'react';
import './RedeemList.styles.css'

import RedeemItem from '../RedeemItem/RedeemItem.component'

const RedeemList = ({ items }) => {

    return (
        <div className='redeem-list-page'>
            <div className='redeem-list-container'>
                {
                    items.map(item => {
                        return <RedeemItem key={item._id} item={item} />
                    })
                }
            </div>
        </div>
    );
};

export default RedeemList